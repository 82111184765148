<div class="card mt-4">
    <h4 class="card-header">Admin</h4>
    <div class="card-body">
        <p>{{'Text3' | translate}}</p>
        <p class="mb-1">{{'Text4' | translate}}</p>
        <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
        <ul *ngIf="users">
            <li *ngFor="let user of users">{{user.firstName}} {{user.lastName}}</li>
        </ul>
    </div>
</div>