
    <!--<div class="column"><strong>{{'Language' | translate}}</strong>&nbsp;<app-select-language></app-select-language></div>-->


<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-light bg-light static-top">
  <a class="navbar-brand" href="#">
    <img src="./assets/images/logo/logo.png" alt="PrivateFan24 Logo">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" (click)="isShown = !isShown" [attr.aria-expanded]="isShown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngClass]="{ 'show': isShown }" id="navbarResponsive">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/home">Home</a></li>
      <li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/users" *ngIf="isSysAdmin">Users</a></li>
      <li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/admin" *ngIf="isSysAdmin">Admin</a></li>
      <li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/account/login" *ngIf="isLoggedIn" (click)="logout()">Logout</a></li>
      <li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/account/login" *ngIf="!isLoggedIn">Login</a></li>
      <li class="nav-item"><span class="nav-link"><strong>{{'Language' | translate}}</strong></span></li>
      <li class="nav-item dropdown">&nbsp;<app-select-language></app-select-language></li>
    </ul>
  </div>
</nav>

<!-- main app container -->
<!-- <div class="app-container" [ngClass]="{ 'bg-light': user}">
    <alert></alert>
    <router-outlet></router-outlet>
</div> -->

<div class="app-container">
    <alert></alert>
    <router-outlet></router-outlet>
</div>


<!-- <div class="container">
    <div class="column"><img alt="privatefan logo" src="./assets/images/logo/privatefan24.png"></div>
    <div class="column"><strong>{{'Language' | translate}}</strong>&nbsp;<app-select-language></app-select-language></div>
</div>
<div class="container">
    <div class="column"><h1>{{'Welcome' | translate}}</h1></div>
</div>
<div class="container">
    <div class="column">
        <app-comp-a></app-comp-a>
        <app-comp-b></app-comp-b>
    </div>
</div> -->
