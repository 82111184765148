// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//Dev
// export const environment = {
//   production: false,
//   apiUrl: 'http://localhost:5000',
// };

//Prod
export const environment = {
  production: false,
  apiUrl: 'https://rest.privatefan24.com',
  clientUrl: 'https://dev.privatefan24.com',
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
