<div class="card mt-4">
    <h4 class="card-header">Home</h4>
    <div class="card-body">
        <h1>{{'Welcome' | translate}}</h1>
        <p>{{'YourRoleIs' | translate}} <strong>{{user.role}}</strong></p>
        <p>{{'Text1' | translate}}</p>
        <p class="mb-1">{{'Text2' | translate}}</p>
        <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
        <p><img src="{{ user.pictureUrl }}"></p>
        <ul *ngIf="user">
            <li>{{user.firstName}} {{user.lastName}}</li>
        </ul>
    </div>
</div>